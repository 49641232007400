import axios from "axios"

const userAxios = axios
const token = document.head.querySelector('meta[name="csrf-token"]')
userAxios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

if (token) {
    userAxios.defaults.headers.common["X-CSRF-TOKEN"] = token.content
} else {
    console.error("CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token")
}

export default userAxios
